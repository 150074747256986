<!-- 筛选条件列表 -->
<template>
  <div>
    <van-action-sheet
      :value="isShow"
      :style="{ height: '335px' }"
      @cancel="onCancel"
      @click-overlay="onCancel"
    >
      <div class="content">
        <div
          v-for="(filer, idx) in filterTypeArr"
          :key="'filter_' + idx"
          class="filter"
          :class="{ 'filter-active': activeIdx === idx }"
          @click="onFilterActiveClick(idx)"
        >
          {{ filer }}
        </div>
        <div class="confirm" @click="onConfirmClick">确定</div>
      </div>
    </van-action-sheet>
  </div>
</template>
<script>
export default {
  components: {},
  props: ['isShow', 'filrerActiveIdx', 'filterTypeArr'],
  data() {
    return {
      activeIdx: 0,
    };
  },
  watch: {
    isShow: {
      handler: function () {
        if (this.isShow) {
          this.activeIdx = this.filrerActiveIdx;
        }
      },
      immediate: true,
    },
  },
  computed: {},
  methods: {
    onFilterActiveClick(val) {
      this.activeIdx = val;
    },
    onCancel() {
      this.$emit('update:isShow');
    },
    onConfirmClick() {
      this.$emit('onConfirm', this.activeIdx);
    },
  },
  mounted() {},
};
</script>
<style scoped lang="scss">
.content {
  position: relative;
  padding: 18px 16px 30px;
  background: rgba(246, 247, 248, 1);
  height: 100%;
  .filter {
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-items: center;
    border-radius: 6px;
    background: rgba(239, 242, 247, 1);
    padding-left: 14px;
    box-sizing: border-box;
    border: 1px solid rgba(239, 242, 247, 1);
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 1);
    & + .filter {
      margin-top: 8px;
    }
    &.filter-active {
      border: 1px solid rgba(70, 95, 253, 1);
      color: rgba(70, 95, 253, 1);
    }
  }
  .confirm {
    width: 100%;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 23px;
    background: rgba(70, 95, 253, 1);
    font-size: 16px;
    font-weight: 600;
    color: rgba(255, 255, 255, 1);
    margin-top: 21px;
  }
}
.footer {
  position: absolute;
  left: 16px;
  right: 16px;
  bottom: 24px;
  height: 46px;
  display: flex;
  justify-content: space-between;
  .reset {
    width: 28.27vw;
    height: 46px;
    border-radius: 23px;
    border: 2px solid rgba(67, 110, 255, 1);
    font-size: 16px;
    font-weight: 600;
    color: rgba(67, 110, 255, 1);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .confirm {
    width: 58.67vw;
    height: 46px;
    border-radius: 23px;
    background: rgb(67, 110, 255);
    font-size: 16px;
    font-weight: 600;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
::v-deep .van-action-sheet__header {
  background: rgba(246, 247, 248, 1);
}
</style>
