<!-- 筛选条件列表 -->
<template>
  <div>
    <van-action-sheet
      :value="isShow"
      title="选择转派人员"
      :style="{ height: '81%' }"
      @cancel="onCancel"
      @click-overlay="onCancel"
    >
      <div ref="contentRef" class="content">
        <div class="header">
          <div class="searchBarInner">
            <div class="inputWrapper">
              <img class="searchIcon" src="@/assets/imgs/input-search.png" alt="" />
              <input ref="ipt" type="text" v-model="value" placeholder="请选择转派人员" />
              <img
                v-if="showClear"
                @click="clrearValueClick"
                class="clearIcon"
                src="@/assets/imgs/input-clear.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="header-placehold"></div>
        <div class="searchResult">
          <ul ref="listRef" class="list">
            <li class="list-item" v-for="(item, idx) in filterList" :key="'user_' + idx">
              <!--:class="{ 'list-item-active': selectUser.id === item.id }" -->
              <div v-html="hightName(item.realName)"></div>
              <div class="right-bnt">
                <span @click="reassignClick(item)">转派</span>
                <span class="fire" @click="reassignClick(item, true)">加急转派</span>
              </div>
            </li>
          </ul>
          <div class="noData" v-if="filterList.length === 0 && value">
            <img src="@/assets/imgs/no_data.png" alt="" />
            <span>无搜索结果</span>
          </div>
        </div>
      </div>
    </van-action-sheet>
  </div>
</template>
<script>
import { debounce } from '@/utils/index';
import { queryOperationSpecialistUser } from '@/api/apiv2';
import NDialog from '@/components/NDialog/index';

export default {
  components: {},
  props: ['isShow', 'vin'],
  data() {
    return {
      value: '',
      selectUser: {}, // 当前选择的vin
      filterList: [],
    };
  },
  computed: {
    showClear() {
      return this.value !== '';
    },
  },
  watch: {
    isShow() {
      if (this.isShow) {
        this.getOperationSpecialistUserList();
        // 使用防抖联想查询
        this.$nextTick(() => {
          if (!this.selectUser.id) this.$refs.contentRef?.scrollIntoView();
          this.$refs.ipt.addEventListener('input', debounce(this.getSearchVehicleList, 200));
        });
      }
    },
  },
  methods: {
    onCancel() {
      this.value = '';
      this.$emit('update:isShow');
    },
    async reassignClick(v, isFire) {
      const result = await NDialog.confirm({
        title: '确定分配此工单吗?',
        message: `是否确认把工单分配给 ${v.realName}？`,
      });
      if (result === 'confirm') {
        this.selectUser = v;
        v.isFire = isFire;
        this.$emit('onSelectUser', v);
        this.onCancel();
        this.value = '';
      }
    },
    clrearValueClick() {
      this.value = '';
      this.filterList = this.userList;
    },
    async getOperationSpecialistUserList() {
      const res = await queryOperationSpecialistUser(this.vin);
      this.userList = res;
      this.filterList = this.userList;
    },
    getSearchVehicleList() {
      this.filterList = this.userList.filter((v) => {
        return ~v.realName.indexOf(this.value);
      });
    },
    hightName(val) {
      let str = this.value;
      if (!str) return val;
      let replaceName = `<span class='hight-keyword'>${str}</span>`;
      var reg = new RegExp(str, 'g');
      let result = val.replace(reg, replaceName);
      return result;
    },
    reset() {
      this.value = '';
      this.selectUser = {};
    },
  },
  mounted() {},
};
</script>
<style scoped lang="scss">
.content {
  position: relative;
  background: rgba(255, 255, 255, 1);
  height: 100%;
  .header {
    position: fixed;
    width: 100%;
    height: 46px;
    padding: 0 20px 0 16px;

    .searchBarInner {
      // margin-top: 21px;
      width: 100%;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-around;
      .inputWrapper {
        flex-grow: 1;
        height: 32px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-radius: 6px;
        background: rgba(246, 247, 248, 1);

        .searchIcon {
          width: 14px;
          height: 14px;
          margin-left: 9px;
          margin-right: 8px;
        }

        input {
          height: 32px;
          flex-grow: 1;
          font-size: 16px;
          color: #000000;
          caret-color: #436eff;
          background: rgba(246, 247, 248, 1);

          &::placeholder {
            font-size: 14px;
            color: #bfc0cb;
            font-weight: normal;
          }
        }

        .clearIcon {
          width: 18px;
          height: 18px;
          position: absolute;
          top: 7px;
          right: 8px;
        }
      }
    }
  }
  .header-placehold {
    width: 100%;
    height: 46px;
  }
  .searchResult {
    .list {
      padding: 0 20px;
      box-sizing: border-box;
      .list-item {
        width: 100%;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 1);
        border-bottom: 1px solid rgba(235, 235, 235, 1);
        &.list-item-active {
          color: rgb(70, 95, 253);
        }
        .right-bnt {
          display: flex;
          span {
            display: flex;
            width: 76px;
            height: 30px;
            align-items: center;
            justify-content: center;
            border-radius: 16px;
            border: 2px solid rgba(67, 110, 255, 1);
            font-size: 14px;
            font-weight: 600;
            color: rgba(67, 110, 255, 1);
            & + span {
              margin-left: 12px;
            }
            &.fire {
              color: rgba(250, 75, 62, 1);
              border: 2px solid rgba(250, 75, 62, 1);
            }
          }
        }
      }
    }
  }
  .noData {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 120px;

    img {
      width: 160px;
      height: 160px;
    }

    span {
      font-size: 16px;
      color: #222222;
    }
  }
}
</style>
