<!-- 故障列表 -->
<template>
  <div class="fault-action-sheet">
    <van-popup :value="isShow" position="bottom" @click-overlay="onCancel">
      <van-picker
        v-if="reRender"
        value-key="name"
        show-toolbar
        :columns="faultTypeArr"
        @confirm="onConfirmClick"
        @cancel="onCancel"
        title="选择故障类型"
      />
    </van-popup>
  </div>
</template>
<script>
import { getFaultDict } from '@/api/apiv2';

export default {
  components: {},
  props: ['isShow', 'faultActiveIdx'],
  data() {
    return {
      reRender: true,
      faultTypeArr: [],
    };
  },
  watch: {},
  computed: {},
  methods: {
    onCancel() {
      this.$emit('update:isShow');
    },
    onConfirmClick(value) {
      this.$emit('onConfirm', value);
    },
    // 获取故障列表
    async getFaultList() {
      const res = await getFaultDict({ typeCode: 10 });
      if (res['10']) {
        this.faultTypeArr = res['10'].map((item) => {
          return {
            name: item.dictTypeValue,
            value: item.dictType,
          };
        });
      }
    },
    reset() {
      this.reRender = false;
      setTimeout(() => {
        this.reRender = true;
      }, 0);
    },
  },
  mounted() {
    this.getFaultList();
  },
};
</script>
<style scoped lang="scss">
.fault-action-sheet {
  ::v-deep .van-popup {
    border-radius: 16px 16px 0px 0px;
    .van-picker__toolbar {
      height: 55px;
      .van-picker__cancel {
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #000000;
        letter-spacing: 0;
        font-weight: 400;
      }
      .van-picker__confirm {
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #436eff;
        letter-spacing: 0;
        font-weight: 400;
      }
      .van-picker-column__item {
        padding: 0 10px;
      }
    }
  }
}
</style>
