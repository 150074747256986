<template>
  <div class="item-data">
    <div class="top">
      <div class="top-left">
        <span class="vehicle-name">{{ workOrder.vinId }}</span>
        <template v-if="workOrder.urgent">
          <img class="fire" src="../../../assets/imgs/O&MTicketManagement/fire@2x.png" />
          <span class="fire-label">加急</span>
        </template>
      </div>
      <div class="top-right" :data-status="workOrder.status">{{ workOrder.statusName }}</div>
    </div>
    <div class="middle">
      <div class="item-info">
        <span class="k">网格</span>
        <span class="v">{{ workOrder.parkName }}</span>
      </div>
      <div class="item-info">
        <span class="k">故障类型</span>
        <span class="v">{{ workOrder.faultTypeValue }}</span>
      </div>
      <div class="item-info">
        <span class="k">故障时间</span>
        <span class="v">{{ workOrder.faultTime }}</span>
      </div>
    </div>
    <div class="foot">
      <div class="btn" @click="detailClick">查看详情</div>
      <template v-if="workOrder.userType !== 0">
        <div class="btn" v-if="workOrder.showTransfer" @click="reassignmentClick">转派</div>
        <div class="btn" v-if="workOrder.showReceive" @click="acceptClick">接收</div>
        <div class="btn" v-if="workOrder.showDiagnosis" @click="faultDiagnosisClick">故障诊断</div>
        <div class="btn close" v-if="workOrder.showClose" @click="closeClick">关闭工单</div>
      </template>
    </div>
  </div>
</template>

<script>
import { receiveFaultOrder, receiveFaultOrderClose } from '@/api/apiv2';
import { faultDiagnosis, faultDiagnosisDetail } from '@/utils/index';
//
export default {
  components: {},
  data() {
    return {};
  },
  props: {
    workOrder: {
      type: Object,
      default: () => {},
    },
    statusDic: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    statusLabel() {
      const status = this.statusDic.find((item) => item.val === this.workOrder.status);
      if (status) {
        return status.name;
      }
      return '';
    },
  },
  mounted() {
    //
  },
  methods: {
    // 查看详情
    detailClick() {
      // this.$router.push(`/faultOrderDetail?id=${this.workOrder.id}`);
      if (window.location.href.indexOf('//localhost:') > -1) {
        this.$router.push(`/faultOrderDetail?id=${this.workOrder.id}`);
        return;
      }
      faultDiagnosisDetail(
        JSON.stringify({
          id: this.workOrder.id,
          routerUrl: 'faultOrderDetail',
        })
      );
    },
    // 转派
    reassignmentClick() {
      this.$emit('onReassignmentClick', this.workOrder);
    },
    // 接受
    async acceptClick() {
      await receiveFaultOrder({ id: this.workOrder.id });
      this.$emit('onChangeOrderStatus', {
        ...this.workOrder,
        showDiagnosis: true,
        showTransfer: false,
        showReceive: false,
        showClose: false,
        showDetail: false,
        status: 2,
        statusName: '处理中-待诊断',
      });
    },
    // 故障诊断
    faultDiagnosisClick() {
      if (window.location.href.indexOf('//localhost:') > -1) {
        this.$router.push(`/diagnosis?id=${this.workOrder.id}`);
        return;
      }
      faultDiagnosis(
        JSON.stringify({
          id: this.workOrder.id,
          routerUrl: 'diagnosis',
        })
      );
    },
    // 关闭工单
    async closeClick() {
      await receiveFaultOrderClose({ id: this.workOrder.id });
      this.$emit('onChangeOrderStatus', {
        ...this.workOrder,
        showDiagnosis: false,
        showTransfer: false,
        showReceive: false,
        showClose: false,
        showDetail: true,
        status: 4,
        statusName: '已完成',
      });
    },
  },
};
</script>

<style scoped lang="scss">
.item-data {
  border-radius: 12px;
  background: rgba(255, 255, 255, 1);
  padding: 14px;
  box-sizing: border-box;
  margin-bottom: 14px;
  .top {
    display: flex;
    justify-content: space-between;
    &-left {
      display: flex;
      align-items: center;
      .vehicle-name {
        font-size: 16px;
        font-weight: 600;
        color: rgba(0, 0, 0, 1);
      }
      .fire {
        width: 18px;
        height: 18px;
        margin-right: 1px;
        margin-left: 4px;
      }
      .fire-label {
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0px;
        line-height: 16.8px;
        color: rgba(250, 75, 62, 1);
        text-align: left;
        vertical-align: top;
      }
    }
    &-right {
      font-size: 14px;
      font-weight: 600;
      &[data-status='0'],
      &[data-status='1'] {
        color: rgba(255, 45, 85, 1);
      }
      &[data-status='2'] {
        color: rgba(254, 128, 0, 1);
      }
      &[data-status='3'] {
        color: rgba(38, 197, 117, 1);
      }
      &[data-status='4'] {
        color: rgba(67, 110, 255, 1);
      }
    }
  }
  .middle {
    margin: 10px 0 16px;
    .item-info {
      display: flex;
      justify-content: space-between;
      .k {
        max-width: 60px;
        font-size: 13px;
        font-weight: 400;
        color: rgba(151, 152, 171, 1);
      }
      .v {
        flex: 1;
        display: -webkit-box; /* 设置为WebKit内核的弹性盒子模型 */
        -webkit-box-orient: vertical; /* 垂直排列 */
        -webkit-line-clamp: 1; /* 限制显示两行 */
        overflow: hidden; /* 隐藏超出范围的内容 */
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 1);
        text-align: right;
      }
      & + .item-info {
        margin-top: 8px;
      }
    }
  }
  .foot {
    display: flex;
    justify-content: flex-end;
    .btn {
      width: 76px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 600;
      color: rgba(67, 110, 255, 1);
      border-radius: 16px;
      background: rgba(255, 255, 255, 1);
      border: 1px solid rgba(67, 110, 255, 1);
      & + .btn {
        margin-left: 12px;
      }
      &.close {
        border: 1px solid rgba(38, 197, 117, 1);
        color: rgba(38, 197, 117, 1);
      }
    }
  }
}
</style>
