<template>
  <div>
    <van-action-sheet
      :value="isShow"
      title="筛选"
      :style="{ height: '90%', maxHeight: 'unset' }"
      @cancel="onCancel"
      @click-overlay="onCancel"
    >
      <div class="content">
        <div class="status-modal">
          <div class="modal-title">工单状态</div>
          <div class="status-list">
            <div
              v-for="(status, idx) in statusDic"
              :key="'status_' + idx"
              class="status"
              :class="{ 'status-active': filterData.status === status.val }"
              @click="statusClick(status)"
            >
              {{ status.name }}
            </div>
          </div>
        </div>
        <div class="condition-modal" :style="{ marginTop: '13px' }">
          <div class="modal-title">条件筛选</div>
          <div class="condition-box">
            <div class="condition-name" @click="isShowFilterActionSheet = true">
              <span>{{ filterTypeArr[filrerActiveIdx] }}</span>
              <img src="../../../assets/imgs/O&MTicketManagement/arrows@2x.png" />
            </div>
            <div class="condition-value" @click="conditionClick">
              <span v-if="conditionValue">{{ conditionValue }}</span>
              <span v-else class="condition-value-placeholder">{{ conditionPlaceholder }}</span>
            </div>
          </div>
        </div>
        <div class="footer">
          <div class="reset" @click="onResetClick">重置</div>
          <div class="confirm" @click="onConfirmClick">查询</div>
        </div>
      </div>
    </van-action-sheet>
    <FilterActionSheet
      :isShow.sync="isShowFilterActionSheet"
      :filterTypeArr="filterTypeArr"
      :filrerActiveIdx="filrerActiveIdx"
      @onConfirm="handleFilterActionSheetConfirm"
    ></FilterActionSheet>
    <Date
      ref="dateRef"
      :isShowDate.sync="isShowDateActionSheet"
      @onConfirm="handleDateConfirm"
    ></Date>
    <Vehicle
      ref="vehicleRef"
      :isShow.sync="isShowVehicleActionSheet"
      @onSelectVehicle="handleSelectVehicle"
    ></Vehicle>
    <GridAddress
      ref="gridAddressRef"
      :isShow.sync="isShowAddressActionSheet"
      @onConfirm="handleAddreeConfirm"
    ></GridAddress>
    <Fault
      ref="faultRef"
      :isShow.sync="isShowFaultActionSheet"
      @onConfirm="handleFaultConfirm"
    ></Fault>
  </div>
</template>
<script>
import FilterActionSheet from './FilterActionSheet.vue';
import Date from './Date.vue';
import Vehicle from './Vehicle.vue';
import GridAddress from './GridAddress.vue';
import Fault from './Fault.vue';
export default {
  components: { FilterActionSheet, Date, Vehicle, GridAddress, Fault },
  props: ['isShow', 'statusDic'],
  data() {
    return {
      filterData: {
        status: -1,
        date: [],
        vehicle: {},
        parkCode: [],
        pro: '',
        city: '',
        fault: {},
      },
      filterTypeArr: ['按日期', '按车辆ID', '按省市/网格', '按故障类型'],
      filrerActiveIdx: 0,
      isShowFilterActionSheet: false, // 筛选条件选择
      isShowDateActionSheet: false, // 日期选择
      isShowVehicleActionSheet: false, //车辆ID筛选
      isShowAddressActionSheet: false, // 省市/网格筛选
      isShowFaultActionSheet: false, // 故障筛选
    };
  },
  computed: {
    conditionValue() {
      const { date, vehicle, pro, city, parkCode, parkName } = this.filterData;
      if (this.filrerActiveIdx === 0) {
        return date.join(' 至 ');
      } else if (this.filrerActiveIdx === 1) {
        return vehicle.vinId;
      } else if (this.filrerActiveIdx === 2) {
        if (pro && !city) {
          return pro;
        } else if (pro && city && parkCode.length === 0) {
          return `${pro} ${city}`;
        } else if (pro && city && parkCode.length > 0) {
          return parkCode.length === 1
            ? `${pro} ${city} ${parkName}`
            : `${pro} ${city} ${parkCode.length}网格`;
        }
      } else if (this.filrerActiveIdx === 3) {
        return this.filterData.fault.name;
      }
      return '';
    },
    conditionPlaceholder() {
      if (this.filrerActiveIdx === 0) {
        return '请选择日期';
      } else if (this.filrerActiveIdx === 1) {
        return '请选择车辆ID';
      } else if (this.filrerActiveIdx === 2) {
        return '请选择省市/网格';
      } else if (this.filrerActiveIdx === 3) {
        return '请选择故障类型';
      }
    },
  },
  methods: {
    onCancel() {
      this.$emit('update:isShow');
    },
    onResetClick() {
      this.resetConditionFilter();
      this.filrerActiveIdx = 0;
      this.filterData.status = -1;
    },
    onConfirmClick() {
      this.$emit('onConfirm', this.filterData);
    },
    // 状态选择
    statusClick(payload) {
      this.filterData.status = payload.val;
    },
    // 选择筛选条件类型
    handleFilterActionSheetConfirm(val) {
      if (this.filrerActiveIdx !== val) {
        this.filrerActiveIdx = val;
        this.resetConditionFilter();
      }
      this.isShowFilterActionSheet = false;
    },
    // 显示指定的筛选条件弹窗
    conditionClick() {
      if (this.filrerActiveIdx === 0) {
        this.isShowDateActionSheet = true;
      } else if (this.filrerActiveIdx === 1) {
        this.isShowVehicleActionSheet = true;
      } else if (this.filrerActiveIdx === 2) {
        this.isShowAddressActionSheet = true;
      } else if (this.filrerActiveIdx === 3) {
        this.isShowFaultActionSheet = true;
      }
    },
    // 重置条件筛选
    resetConditionFilter() {
      this.filterData.date = [];
      this.filterData.vehicle = {};
      this.filterData.parkCode = [];
      this.filterData.pro = '';
      this.filterData.city = '';
      this.filterData.fault = {};
      this.$refs.dateRef.reset();
      this.$refs.vehicleRef.reset();
      this.$refs.gridAddressRef.reset();
      this.$refs.faultRef.reset();
    },
    // 选中筛选日期
    handleDateConfirm(dateArr) {
      this.filterData.date = dateArr;
    },
    // 选中vin
    handleSelectVehicle(v) {
      this.filterData.vehicle = v;
    },
    //选中省市、网格
    handleAddreeConfirm({ parkCodeList, city, pro, parkName }) {
      this.filterData.parkCode = parkCodeList;
      this.filterData.city = city;
      this.filterData.pro = pro;
      this.filterData.parkName = parkName;
    },
    // 选择故障类型
    handleFaultConfirm(fault) {
      this.filterData.fault = fault;
      this.isShowFaultActionSheet = false;
    },
  },
  mounted() {},
};
</script>
<style scoped lang="scss">
.content {
  position: relative;
  padding: 13px 16px 24px;
  background: rgba(246, 247, 248, 1);
  height: 100%;
  .modal-title {
    font-size: 16px;
    font-weight: 600;
    color: rgba(0, 0, 0, 1);
    margin-bottom: 12px;
  }
  .status-modal {
    .status-list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 9px 5px;
      .status {
        width: 100%;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 1);
        border-radius: 26px;
        background: rgba(255, 255, 255, 1);
        &.status-active {
          background: rgba(70, 95, 253, 1);
          color: rgba(255, 255, 255, 1);
        }
      }
    }
  }
  .condition-modal {
    .condition-box {
      width: 100%;
      height: 48px;
      border-radius: 6px;
      background: rgba(255, 255, 255, 1);
      display: flex;
      align-items: center;
      padding-left: 14px;
      box-sizing: border-box;
      .condition-name {
        display: flex;
        align-items: center;
        width: fit-content;
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 1);
        padding-right: 6px;
        box-sizing: border-box;
        border-right: 1px solid rgba(235, 235, 235, 1);
        img {
          width: 18px;
          margin-left: 6px;
        }
      }
      .condition-value {
        flex: 1;
        padding-left: 12px;
        box-sizing: border-box;
        font-size: 14px;
        font-weight: 600;
        color: rgba(0, 0, 0, 1);
        display: -webkit-box; /* 设置为WebKit内核的弹性盒子模型 */
        -webkit-box-orient: vertical; /* 垂直排列 */
        -webkit-line-clamp: 1; /* 限制显示两行 */
        overflow: hidden; /* 隐藏超出范围的内容 */
        &-placeholder {
          font-size: 14px;
          font-weight: 400;
          color: rgba(191, 192, 203, 1);
        }
      }
    }
  }
}
.footer {
  position: absolute;
  left: 16px;
  right: 16px;
  bottom: 24px;
  height: 46px;
  display: flex;
  justify-content: space-between;
  .reset {
    width: 28.27vw;
    height: 46px;
    border-radius: 23px;
    border: 2px solid rgba(67, 110, 255, 1);
    font-size: 16px;
    font-weight: 600;
    color: rgba(67, 110, 255, 1);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .confirm {
    width: 58.67vw;
    height: 46px;
    border-radius: 23px;
    background: rgb(67, 110, 255);
    font-size: 16px;
    font-weight: 600;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
::v-deep .van-action-sheet__header {
  background: rgba(246, 247, 248, 1);
}
</style>
