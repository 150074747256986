<!-- 筛选条件列表 -->
<template>
  <div>
    <van-action-sheet
      :value="isShow"
      :style="{ height: '81%' }"
      @cancel="onCancel"
      @click-overlay="onCancel"
    >
      <div ref="contentRef" class="content">
        <div class="header">
          <div class="header-title">
            <div class="cancel" @click="onCancel">取消</div>
          </div>
          <div class="searchBarInner">
            <div class="inputWrapper">
              <img class="searchIcon" src="@/assets/imgs/input-search.png" alt="" />
              <input ref="ipt" type="text" v-model="value" placeholder="请输入车辆ID" />
              <img
                v-if="showClear"
                @click="clrearValueClick"
                class="clearIcon"
                src="@/assets/imgs/input-clear.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="header-placehold"></div>
        <div class="searchResult">
          <ul ref="listRef" class="list">
            <li
              class="list-item"
              v-for="(item, idx) in filterList"
              :key="'vin_' + idx"
              :class="{ 'list-item-active': selectVehicle.vin === item.vin }"
              @click="onVehicleClick(item)"
            >
              <div v-html="hightName(item.vinId)"></div>
            </li>
          </ul>
          <div class="noData" v-if="filterList.length === 0 && value">
            <img src="@/assets/imgs/no_data.png" alt="" />
            <span>无搜索结果</span>
          </div>
        </div>
      </div>
    </van-action-sheet>
  </div>
</template>
<script>
import { debounce } from '@/utils/index';
import { selectAuthVinListByUserId } from '@/api/apiv2';
export default {
  components: {},
  props: ['isShow'],
  data() {
    return {
      value: '',
      selectVehicle: {}, // 当前选择的vin
      filterList: [],
    };
  },
  computed: {
    showClear() {
      return this.value !== '';
    },
  },
  watch: {
    isShow() {
      if (this.isShow) {
        this.filterList = this.vinIdList;
        // 使用防抖联想查询
        this.$nextTick(() => {
          if (!this.selectVehicle.vin) this.$refs.contentRef?.scrollIntoView();
          this.$refs.ipt.addEventListener('input', debounce(this.getSearchVehicleList, 200));
        });
      }
    },
  },
  methods: {
    onCancel() {
      this.value = '';
      this.$emit('update:isShow');
    },
    onVehicleClick(v) {
      this.selectVehicle = v;
      this.$emit('onSelectVehicle', v);
      this.onCancel();
    },
    clrearValueClick() {
      this.value = '';
      this.filterList = this.vinIdList;
    },
    async getVehicleList() {
      const res = await selectAuthVinListByUserId({});
      this.vinIdList = res;
    },
    getSearchVehicleList() {
      this.filterList = this.vinIdList.filter((v) => {
        return ~v.vinId.indexOf(this.value);
      });
    },
    hightName(val) {
      if (!val) return val;
      let str = this.value;
      let replaceName = `<span class='hight-keyword'>${str}</span>`;
      var reg = new RegExp(str, 'g');
      let result = val.replace(reg, replaceName);
      return result;
    },
    reset() {
      this.value = '';
      this.selectVehicle = {};
    },
  },
  mounted() {
    this.getVehicleList();
  },
};
</script>
<style scoped lang="scss">
::v-deep .van-action-sheet__header {
  background: rgba(255, 255, 255, 1) !important;
}
.content {
  position: relative;
  background: rgba(255, 255, 255, 1);
  height: 100%;
  .header {
    position: fixed;
    width: 100%;
    height: 94px;
    padding: 0 20px 0 16px;
    background: rgba(250, 250, 250, 1);
    border-radius: 16px 16px 0px 0px;
    .header-title {
      width: 100%;
      height: 46px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      color: rgba(0, 0, 0, 1);
      font-weight: 400;
    }
    .searchBarInner {
      // margin-top: 21px;
      width: 100%;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-around;
      .inputWrapper {
        flex-grow: 1;
        height: 32px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background: white;
        border-radius: 6px;

        .searchIcon {
          width: 14px;
          height: 14px;
          margin-left: 9px;
          margin-right: 8px;
        }

        input {
          height: 32px;
          flex-grow: 1;
          font-size: 16px;
          color: #000000;
          caret-color: #436eff;
          background: white;
          &::placeholder {
            font-size: 14px;
            color: #bfc0cb;
            font-weight: normal;
          }
        }

        .clearIcon {
          width: 18px;
          height: 18px;
          position: absolute;
          top: 7px;
          right: 8px;
        }
      }
    }
  }
  .header-placehold {
    width: 100%;
    height: 46px;
  }
  .searchResult {
    .list {
      padding: 0 20px;
      padding-top: 48px;
      box-sizing: border-box;
      .list-item {
        width: 100%;
        height: 48px;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 1);
        border-bottom: 1px solid rgba(235, 235, 235, 1);
        &.list-item-active {
          color: rgb(70, 95, 253);
        }
      }
    }
  }
  .noData {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 120px;

    img {
      width: 160px;
      height: 160px;
    }

    span {
      font-size: 16px;
      color: #222222;
    }
  }
}
</style>
