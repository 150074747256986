<!-- 日期筛选 -->
<template>
  <div class="date-filter">
    <van-calendar
      :default-date="selectDate"
      first-day-of-week="1"
      type="range"
      v-model="show"
      color="#436EFF"
      :min-date="minDate"
      :max-date="maxDate"
      :show-subtitle="false"
      :show-confirm="false"
      :closeable="false"
      :style="{ height: '60%' }"
      @close="onClose"
      @select="onSelect"
    >
      <template #title>
        <div class="header">
          <div class="cancel" @click="onClose">取消</div>
          <div class="confirm" @click="onConfirm">确定</div>
        </div>
      </template>
    </van-calendar>
  </div>
</template>

<script>
import moment from 'moment';
import { Toast } from 'vant';
let today = new Date(Date.now());
let yestoday = moment().subtract(1, 'days').toDate();
export default {
  props: ['isShowDate'],
  data() {
    return {
      show: this.isShowDate,
      minDate: new Date(2024, 12, 1),
      maxDate: new Date(),
      selectDate: [yestoday, today],
    };
  },
  computed: {},
  watch: {
    isShowDate: {
      handler(newVal) {
        this.show = newVal;
      },
      immediate: true,
    },
  },
  created() {},

  mounted() {},

  methods: {
    onSelect(date) {
      console.log(date);
      this.selectDate = date;
    },
    formatDate(date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    },
    onClose() {
      this.$emit('update:isShowDate');
    },
    onConfirm() {
      const [start, end] = this.selectDate;
      if (!end) {
        Toast({
          message: `请选择结束日期`,
          className: 'response-toast',
          forbidClick: false,
        });
        return;
      }
      const dateArr = [this.formatDate(start), this.formatDate(end)];
      this.$emit('onConfirm', dateArr);
      this.onClose();
    },
    reset() {
      this.selectDate = [yestoday, today];
    },
  },
};
</script>

<style scoped lang="scss">
.date-filter {
  .header {
    width: 100%;
    height: 46px;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: #000000;
    font-weight: 400;
    padding-top: 5px;
    .cancel,
    .confirm {
      padding: 0 20px;
    }
    .confirm {
      color: #436eff;
    }
  }
}
::v-deep .van-popup__close-icon--top-right {
  display: none;
}
</style>
