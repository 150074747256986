<template>
  <van-popup
    :value="isShow"
    position="bottom"
    round
    @click-overlay="onClose"
    :style="{ height: '500px' }"
  >
    <div class="address-body">
      <div class="header">
        <div class="header-title">
          <div class="cancel" @click="onClose">取消</div>
          <div
            class="confirm active-color"
            :class="{ 'disabled-color': parkCodeList.length === 0 && provinceIdx === -1 }"
            @click="onConfirm"
          >
            确定
          </div>
        </div>
        <div class="address-title">
          <div class="province ellipsis">省份</div>
          <div class="city ellipsis">城市</div>
          <div class="grid ellipsis">
            网格<span class="grid-num" v-if="selectParkCodeNum">{{ selectParkCodeNum }}</span>
          </div>
        </div>
      </div>
      <div class="header-placehold"></div>
      <div class="address-content black-color">
        <ul :style="{ height: '406px' }">
          <li class="province">
            <div
              class="cell ellipsis"
              v-for="(province, idx) in location"
              :key="'province_' + idx"
              :class="{ 'active-color': provinceIdx === idx }"
              @click="provinceClick(province, idx)"
            >
              {{ province.pro }}
            </div>
          </li>
          <li class="city">
            <div
              class="cell ellipsis"
              v-for="(city, idx) in cityList"
              :key="'city_' + idx"
              :class="{ 'active-color': cityIdx === idx }"
              @click="cityClick(city, idx)"
            >
              {{ city.city }}
            </div>
          </li>
          <li class="grid">
            <div
              class="cell ellipsis"
              v-for="(grid, idx) in gridList"
              :key="'grid_' + idx"
              :class="{ 'active-color': parkCodeList.indexOf(grid.parkCode) > -1 }"
              @click="gridClick(grid)"
            >
              {{ grid.parkName }}
            </div>
          </li>
        </ul>
      </div>
    </div>
  </van-popup>
</template>

<script>
import { queryProAndCityWithFaultOrder } from '@/api/apiv2';

export default {
  name: 'GridAddress',
  props: ['isShow'],
  data() {
    return {
      location: [],
      cityList: [],
      gridList: [],
      provinceIdx: -1, // 选中省份的下标
      cityIdx: -1, // 选中城市的下标
      parkCodeList: [], // 选中的网格列表
    };
  },
  computed: {
    selectParkCodeNum() {
      if (this.parkCodeList.includes(-1)) {
        return this.gridList.length > 0 ? this.gridList.length - 1 : 0;
      } else {
        return this.parkCodeList.length;
      }
    },
  },
  watch: {
    isShow() {
      if (this.isShow) {
        this.getGridData();
      }
    },
  },
  created() {},

  mounted() {},

  methods: {
    onClose() {
      this.$emit('update:isShow');
    },
    provinceClick(item, idx) {
      this.cityList = item.citys;
      this.gridList = [];
      this.parkCodeList = [];
      this.provinceIdx = idx;
      this.cityIdx = -1;
    },
    cityClick(item, idx) {
      this.gridList = [{ parkName: '全部', parkCode: -1 }, ...item.parkList];
      this.parkCodeList = [];
      this.cityIdx = idx;
    },
    gridClick(item) {
      const idx = this.parkCodeList.findIndex((id) => item.parkCode === id);
      if (idx > -1) {
        // 取消选中
        this.parkCodeList.splice(idx, 1);
      } else {
        if (item.parkCode === -1) {
          // 选中全部
          this.parkCodeList = [-1];
        } else {
          if (this.parkCodeList.includes(-1) && item.parkCode !== -1) {
            // 选择其它的时候,如果已选择了全部,需要把全部置灰
            this.parkCodeList = [];
          }
          this.parkCodeList.push(item.parkCode);
        }
      }
    },
    onConfirm() {
      const proCity = this.location[this.provinceIdx];
      const arr = this.parkCodeList.includes(-1) ? [] : [...this.parkCodeList];
      const city = this.cityIdx === -1 ? {} : this.cityList[this.cityIdx];
      let parkName = '';
      if (arr.length === 1) {
        // 只选中一个网格的时候需要显示名字
        const part = this.gridList.find((item) => item.parkCode == arr[0]);
        parkName = part.parkName;
      }
      this.$emit('onConfirm', {
        parkCodeList: arr,
        city: city.city,
        pro: proCity.pro,
        parkName,
      });
      this.onClose();
    },
    // 获取地址信息
    getGridData() {
      queryProAndCityWithFaultOrder({}).then((res) => {
        this.location = res;
      });
    },
    reset() {
      this.gridList = [];
      this.provinceIdx = -1;
      this.cityIdx = -1;
      this.parkCodeList = [];
    },
  },
};
</script>

<style scoped lang="scss">
.address-body {
  display: flex;
  flex-direction: column;
  .header {
    position: fixed;
    width: 100%;
    height: 84px;
    background: #fafafa;
    border-bottom: 1px solid #ebebeb;
    border-radius: 16px 16px 0px 0px;
    .header-title {
      width: 100%;
      height: 46px;
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      color: #000000;
      font-weight: 400;
      padding-top: 16px;
      .cancel,
      .confirm {
        padding: 0 20px;
      }
    }
    .address-title {
      width: 100%;
      height: 42px;
      display: flex;
      align-items: center;
      padding-left: 8px;
      font-size: 14px;
      color: #000000;
      font-weight: 600;
      .grid {
        display: flex;
        align-items: center;
        .grid-num {
          display: flex;
          width: 20px;
          height: 20px;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          color: #ffffff;
          font-weight: 600;
          background: #436eff;
          border-radius: 50%;
          margin-left: 6px;
        }
      }
    }
  }
  .header-placehold {
    width: 100%;
    height: 84px;
  }
  .address-content {
    flex: 1;
    padding: 10px 5px 0 8px;
    ul {
      width: 100%;
      display: flex;
      font-size: 15px;
      color: rgba(0, 0, 0, 1);
      overflow: hidden;
      li {
        overflow: auto;
        .cell {
          display: flex;
          align-items: center;
          min-height: calc(44px - 5px);
          // line-height: 44px;
          font-size: 14px;
          margin-bottom: 5px;
        }
      }
    }
  }
  .province {
    width: 21.8%;
    padding-left: 12px;
  }
  .city {
    width: 32.7%;
    padding-left: 12px;
  }
  .grid {
    flex: 1;
    padding-left: 12px;
    padding-right: 5px;
  }
}
.ellipsis {
  display: block;
  overflow: hidden;
  word-break: keep-all;
  white-space: wrap;
  // white-space: nowrap;
  // text-overflow: ellipsis;
}
.black-color {
  color: black;
}
.active-color {
  color: #436eff;
  font-weight: 600;
}
.disabled-color {
  color: #9eb3fc;
}
</style>
