import request from '@/utils/requestv2';
import env from '../../env';

const { NDPURL } = env;

export const getVinInfoForVedio = (data) =>
  request.post('/vehicle-center/vehicle/getVinInfoForVedio', data); // 查询灯光

export const lampControl = (data) => request.post('/vehicle-center/vehicle/lampControl', data); // 修改灯光

export const batchCancelDelivery = (data) =>
  request.post('/order-center/expressOrder/batchCancelDelivery', data); // 批量取消配送

export const batchSecondDelivery = (data) =>
  request.post('/order-center/expressOrder/batchSecondDelivery', data); // 批量二次配送

export const batchOfflineDelivery = (data) =>
  request.post('/order-center/expressOrder/batchOfflineDelivery', data); // 批量线下配送

export const getParkMoveCarPOI = (data) =>
  request.post('/nem-center/poi/getParkMoveCarPOI', data, {
    customHandle: true,
  }); // 获取【支持场内自动挪车】的网点下的挪车点

export const getPoiListByType = (data) => request.post('/nem-center/poi/getPoiListByType', data); // 根据类型获取支持场内挪车下挪车点集合

export const infieldMoveMissionDispatch = (data) =>
  request.post('/vehicle-center/vehicleDispatch/infieldMoveMissionDispatch', data, {
    customHandle: true,
  }); // 根据类型获取支持场内挪车下挪车点集合

export const selectPictureByPoiId = (data) =>
  request.post('/nem-center/poi/selectPictureByPoiId', data); // 查询poi图片
export const batchInsertPicture = (data) =>
  request.post('/nem-center/poi/batchInsertPicture', data); // 批量插入poi图片信息列表
export const deletePicture = (data) => request.post('/nem-center/poi/deletePicture', data); // 删除poi图片
export const uploadPicture = (data) => request.post('/nem-center/poi/uploadPicture', data); // 增加poi图片

export const deletePoi = (data) => request.post('/nem-center/poi/deletePoi', data); // 删除poi

export const searchNearestStationList = (data) =>
  request.post('/nem-center/poi/searchNearestStationList', data); // 搜索POI

export const createPoi = (data) => request.post('/nem-center/poi/createPoi', data); // 创建POI

export const createAppPoi = (data) => request.post('/nem-center/app/poi/createPoi', data); // 创建 App POI

export const getListMission = (data) =>
  request.post('/vehicle-center/callVehicleMission/listMission', data); // 叫车记录
export const getCallVehicleStation = (data) =>
  request.get('/vehicle-center/station/getCallVehicleStation', data); // 叫车-获取装货点卸货点

export const callVehicle = (data) =>
  request.post('/vehicle-center/callVehicleMission/callVehicle', data); // 叫车

export const getStationMissionList = (data) =>
  request.post('/vehicle-center/stationMission/list', data, {
    customHandle: true,
  }); // 站点任务列表
export const getrefreshNum = (data) =>
  request.get('/vehicle-center/stationMission/refreshNum', data, {
    customHandle: true,
  }); // 最多刷新数量

export const coordinateTransform = (data) =>
  request.post(`${NDPURL}aiview/out/coord/info`, data, {
    withoutToken: true,
    customHandle: true,
  });

// 获取停车场信息
export const getParkingPoi = (data) =>
  request.get('/vehicle-center/parkingSpaceInfo/getParkingPoi', data);

// 泊车车位状态查询
export const queryParkingStatus = (data) =>
  request.post('/vehicle-center/parkingSpaceInfo/dispatch/queryParkingStatus', data);

// 泊车车位状态查询
export const queryRelatedParkingTasks = (data) =>
  request.post('/vehicle-center/parkingSpaceInfo/dispatch/queryRelatedParkingTasks', data);

// 车位锁定释放
export const releaseAndLockParking = (data) =>
  request.post('/vehicle-center/parkingSpaceInfo/dispatch/releaseAndLockParking', data, {
    customHandle: true,
  });

// 获取用户权限下RTK车辆
export const getRTKVehicles = () => request.post('/nem-center/app/vehicle/getRTKVehicles', {});
// 获取用户权限下当前AOI的车辆
export const getVehiclesForAOI = (data) =>
  request.post('/nem-center/app/vehicle/getVehiclesForAOI', data);

// 更新高精坐标
export const updatePoiLatAndLng = (data) =>
  request.post('/nem-center/app/poi/updatePoiLatAndLng', data);

export const getAllSpecialRiskPoi = (data) =>
  request.get('/nem-center/app/poi/getAllSpecialRiskPoi', data); // 获得特殊标注风险
export const getRiskPoiInfo = (id) => request.get(`/nem-center/app/poi/getRiskPoiInfo?id=${id}`);
// 创建或更新风险点
export const saveOrUpdateRiskPoi = (data) =>
  request.post('/nem-center/app/poi/saveOrUpdateRiskPoi', data);

// 更新POI
export const updateAppPoi = (data) => request.post('/nem-center/app/poi/updatePoi', data);

// 获取权益列表
export const fsdList = (data) =>
  request.get('/vehicle-center/vehicleLicense/licenseFsdList', { params: data });

// 批量校验POI是否有停车指引
export const checkPoiExistPoiPicture = (data) =>
  request.post('/nem-center/app/poi/checkPoiExistPoiPicture', data);
// 当前用户是否有数据权限
export const estimateHaveDataAuth = (data) =>
  request.post('/nem-center/app/region/estimateHaveDataAuth', data);

export const batchInsertRiskPicture = (data) =>
  request.post('/nem-center/app/poi/batchInsertRiskPicture', data); // 批量插入poi图片信息列表

export const checkExistHome = (data) => request.post('/nem-center/app/poi/checkExistHome', data); // 检测是否有home
export const getAuthParkList = (data) => request.post('/nem-center/region/getAuthParkList', data); // 当前权限下的网点列表
/***维修工单接口 */
export const createFaultOrder = (data) =>
  request.post('vehicle-center/faultOrder/create', data, { customHandle: true }); // 创建故障工单
export const getFaultOrderList = (data) =>
  request.post('/vehicle-center/faultOrder/pageList', data, { customHandle: true }); // 工单列表
export const getFaultOrderDetail = (data) =>
  request.post('/vehicle-center/faultOrder/detail', data); //   工单详情
export const transferFaultOrder = (data) =>
  request.post('/vehicle-center/faultOrder/transfer', data, { customHandle: true }); //   工单转派
export const receiveFaultOrder = (data) => request.post('/vehicle-center/faultOrder/receive', data); //   工单接收
export const receiveFaultOrderClose = (data) =>
  request.post('/vehicle-center/faultOrder/close', data); //   工单关闭
export const saveOrUpdateFaultDiagnosis = (data) =>
  request.post('vehicle-center/faultOrder/saveOrUpdateFaultDiagnosis', data); //   完成故障诊断
export const getFaultStatusEnum = (data) => request.get('/vehicle-center/enum/getStatusEnum', data); //  获取工单状态枚举
export const getFaultDict = (data) => request.post('/vehicle-center/faultOrder/getFaultDict', data); //  诊断时所有故障类型与故障责任判定下拉字典信息
export const queryOperationSpecialistUser = (data) =>
  request.get(`/user-center/userManage/queryOperationSpecialistUser?vin=${data}`); //   查询运维专家下的运营人员
export const selectAuthVinListByUserId = (data) =>
  request.post('/user-center/userManage/selectAuthVinListByUserId', data); //    获取用户权限下的车辆
export const queryProAndCityWithFaultOrder = (data) =>
  request.post('/vehicle-center/park/queryProAndCityWithFaultOrder', data); //    获取用户的省市
/***POI校准接口 */
export const getGryByParkCode = (data) =>
  request.get(`/nem-center/app/region/getGryByParkCode?parkCode=${data}`); // 根据parkCode获取灰度配置
export const getPoiCalibrationCache = (data) =>
  request.post(`/nem-center/app/poi/getPoiCalibrationCache`, data); // 获取站点校准的缓存数据
export const batchSaveRoute = (data) =>
  request.post(`/nem-center/app/route/batchSaveRoute`, data, { customHandle: true }); // 批量保存路线接口
export const checkPOICanBeCalibrate = (data) =>
  request.post(`/nem-center/app/poi/checkPOICanBeCalibrate`, data, { customHandle: true }); // POI校准前的校验接口

export const getDeliveryInfoPageList = (data = {}) =>
  request.post('/oms-center/order/getDeliveryInfoPageList', data); // 获取发货信息分页列表
