<template>
  <transition name="fade">
    <div v-if="show" class="n-dialog">
      <div class="dialog-content">
        <div class="title">
          <span>{{ title ?? '提示' }}</span>
          <img
            class="close"
            v-if="showClose"
            src="../../assets/imgs/closePage.png"
            @click="handleClick('close')"
            alt=""
          />
        </div>
        <div class="message" v-html="message"></div>
        <div class="button">
          <div v-if="!isHideCancel" class="button-cancel" @click="handleClick('cancel')">
            {{ cancelText ?? '取消' }}
          </div>
          <div
            :class="[loading ? 'disabled-ok' : 'button-ok', isHideCancel ? 'one-button' : '']"
            @click="handleClick('confirm')"
            style="position: relative"
          >
            <van-loading
              style="position: absolute; left: 28%; display: inline-block"
              class="loading"
              v-if="loading"
            ></van-loading>
            <span :style="loading ? 'position: relative; left: 10px;' : ''">{{
              okText ?? '确定'
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      title: '提示',
      message: null,
      show: false,
      resolve: null,
      okText: '确定',
      cancelText: '取消',
      showClose: false,
      isLoding: false,
      loading: false,
      isHideCancel: true,
    };
  },
  methods: {
    handleClick(status) {
      if (this.resolve) {
        this.resolve(status);
        if (this.isLoding && status === 'confirm') {
          this.loading = true;
        } else {
          this.show = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.n-dialog {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.5);
  pointer-events: all;
  .dialog-content {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100vw;
    background: white;
    border-radius: 16px 16px 0px 0px;
    padding: 20px;
    .title {
      text-align: center;
      font-size: 20px;
      color: #222222;
      font-weight: bold;
      position: relative;
      .close {
        position: absolute;
        width: 24px;
        height: 24px;
        top: 0;
        right: 0;
        z-index: 2;
      }
    }
    .message {
      margin: 18px 24px 24px;
      font-size: 16px;
      color: #222222;
      text-align: center;
    }
    .button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      div {
        height: 40px;
        text-align: center;
        line-height: 40px;
        border-radius: 24px;
        font-weight: bold;
        font-size: 14px;
        flex-grow: 1;
      }
      &-cancel {
        background: #ecedf2;
        color: #222222;
        margin-right: 20px;
      }
      &-ok {
        background: #436eff;
        color: #ffffff;
      }
    }
    .button .disabled-ok {
      color: #fff;
      background: rgba(142, 168, 255, 1);
    }
    .button .loading {
      color: #fff;
      ::v-deep .van-loading__spinner {
        width: 18px;
        height: 18px;
      }
      ::v-deep .van-loading__text {
        color: rgba(255, 255, 255, 1);
      }
    }
    .one-button {
      flex-grow: unset !important;
      width: 158px;
      margin: auto;
    }
  }
}
</style>
